<template>
     <div id="accountManage">
        <div class="content-header">
            <div class="leftInfo">
                <span class="headerName">{{activePageInfo.headerName}}</span>
            </div>
        </div>
         <div class="accountManage-content">
             <div class="content-box" v-loading="loading">
                 <div class="table-operation">
                     <div class="search">

                     </div>
                     <div class="add">
                         <el-button size="medium" type="primary" @click="clickClassHandle">用户组管理</el-button>
                         <el-button size="medium" type="primary" @click="addAccount">新增账号</el-button>
                     </div>
                 </div>
               <!--  <div class="module-result-content-field">
                     <div class="result-content-field">
                         <el-table
                                 :data="accountList"
                                 width="100%"
                                 height="calc(100vh - 200px)"
                                 class="tableClass"
                                 :header-cell-style="{fontSize:'14px', fontWeight:'600',color:'#366AFF'}"
                                 :row-style="{fontSize:'14px',cursor:'pointer',height:'72px',lineHeight:'72px',padding:'0!important'}"
                                 :cell-style="{padding:'0!important'}"
                         >
                             <el-table-column
                                     label="账号"
                                     width="220"
                                     style="margin-left: 50px;"
                                     align="center"
                                     header-align="center"
                                     show-overflow-tooltip
                             >
                                 <template slot-scope="scope">
                                     <div class="table-item-account" style="display: flex">
                                         <div class="icon" :class="scope.row.roleName == '超级管理员'? 'admin':'' ">
                                             <i class="arsenal_icon arsenaluser-cog-solid"></i>
                                         </div>
                                         <div style=" white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                             <span>{{scope.row.account}}</span>
                                         </div>

                                     </div>
                                 </template>
                             </el-table-column>
                             <el-table-column
                                     label="姓名"
                                     align="center"
                                     header-align="center"
                                     width="150"
                                     :show-overflow-tooltip="true"
                             >
                                 <template slot-scope="scope">
                                     <div class="table-item">{{scope.row.name}}</div>
                                 </template>
                             </el-table-column>
                             <el-table-column
                                     label="账号类型"
                                     align="center"
                                     header-align="center"
                                     width="120"
                             >
                                 <template slot-scope="scope">
                                     <div class="table-item" >{{scope.row.roleName}}</div>
                                 </template>
                             </el-table-column>

                             <el-table-column
                                     label="用户组"
                                     align="center"
                                     header-align="center"
                                     style="text-align: center"
                                     width="120"
                                     :show-overflow-tooltip="true"
                             >
                                 <template slot-scope="scope">
                                     <div class="table-item" >{{scope.row.groupName}}</div>
                                 </template>
                             </el-table-column>
                             <el-table-column
                                     label="机器人权限"
                                     align="center"
                                     header-align="center"
                                     width="400"
                             >
                                 <template slot-scope="scope">
                                     <div class="table-item" >
                                         <el-popover
                                                 placement="right"
                                                 width="=300"
                                                 trigger="click">
                                             <div class="item-bot-click">
                                                 <div v-for="(i,cindex) in JSON.parse(accountList[scope.$index].privileges)" :key="cindex">
                                                     <span>{{i.botName}}</span>
                                                 </div>
                                             </div>
                                             <el-button style="border:none;line-height:45px;height:45px;overflow:hidden" slot="reference">
                                                 <div class="item-bot" style="margin-top:-12px;height:40px">
                                                     <div v-for="(i,cindex) in JSON.parse(accountList[scope.$index].privileges)" :key="cindex">
                                                         {{i.botName}}
                                                         <i v-if="i.botName" class="arsenal_icon arsenalellipsis-h-solid"></i>
                                                     </div>
                                                 </div>
                                             </el-button>
                                         </el-popover>
                                     </div>
                                 </template>
                             </el-table-column>
                             <el-table-column
                                     label="状态"
                                     align="center"
                                     header-align="center"
                                     style="text-align: center"
                             >
                                 <template slot-scope="scope">
                                     <div class="table-item" ><span v-if="scope.row.status === 0">未激活</span><span v-if="scope.row.status === 1">使用中</span><span v-if="scope.row.status === 2">禁用</span>  </div>
                                 </template>
                             </el-table-column>
                             <el-table-column
                                     fixed="right"
                                     label="操作"
                                     width="200"
                                     align="center"
                             >
                                 <template slot-scope="scope">
                                     <div class="table-item">
                                         <el-button title="编辑账号" type="primary" @click="editAccount(scope.row.account,scope.row.name,scope.row.groupName,scope.row.roleName,scope.row.privileges,scope.row.userId)" icon="el-icon-edit" circle></el-button>
                                         <el-button title="禁用账号" type="warning" :style="scope.row.status ==='2'? 'background-color:#dddddd;border:1px solid #dddddd':''" :icon="scope.row.status ==='2'? 'el-icon-success':'el-icon-remove'"  :disabled="scope.row.roleId === '1'" @click="changeStatusAccount(scope.row.userId,scope.row.status)" circle></el-button>
                                         <el-button title="删除账号" type="danger" icon="el-icon-delete" @click="deleteAccount(item.userId)" :disabled="scope.row.roleId === '1'" circle></el-button>
                                     </div>

                                 </template>
                             </el-table-column>

                         </el-table>
                     </div>
                 </div>-->
                 <div class="table-title">
                     <div class="table-item" style="width:20%;text-align:left;">
                         账号
                     </div>
                     <div class="table-item" style="width:10%">
                         姓名
                     </div>
                     <div class="table-item" style="width:10%">
                         账号类型
                     </div>
                     <div class="table-item" style="width:10%">
                         用户组
                     </div>
                     <div class="table-item" style="width:20%">
                         机器人权限
                     </div>
                     <div class="table-item" style="width:10%">
                         状态
                     </div>
                     <div class="table-item" style="width:20%">
                         操作
                     </div>
                 </div>
                 <div class="table-box">
                    <div class="table-content" v-for="(item,index) in accountList" :key="index">
                        <div class="table-item-account" style="width:20%">
                            <div class="icon" :class="item.roleName == '超级管理员'? 'admin':'' ">
                                <i class="arsenal_icon arsenaluser-cog-solid"></i>
                            </div>
                            <span>{{item.account}}</span>
                        </div>
                        <div class="table-item" style="width:10%">{{item.name}}</div>
                        <div class="table-item" style="width:10%">{{item.roleName}}</div>
                        <div class="table-item" style="width:10%">{{item.groupName}}</div>
                        <div class="table-item" style="width:20%">
                            <el-popover
                                placement="right"
                                width="=300"
                                trigger="click">
                                <div class="item-bot-click">
                                    <div v-for="(i,cindex) in JSON.parse(accountList[index].privileges)" :key="cindex">
                                        <span>{{i.botName}}</span>
                                    </div>
                                </div>
                                <el-button style="border:none;line-height:45px;height:45px;overflow:hidden" slot="reference">
                                <div class="item-bot" style="margin-top:-12px;height:40px">
                                    <div v-for="(i,cindex) in JSON.parse(accountList[index].privileges)" :key="cindex">
                                        {{i.botName}}
                                        <i v-if="i.botName" class="arsenal_icon arsenalellipsis-h-solid"></i>
                                    </div>
                                </div>
                                </el-button>
                            </el-popover>
                        </div>
                        <div class="table-item" style="width:10%"><span v-if="item.status == 0">未激活</span><span v-if="item.status == 1">使用中</span><span v-if="item.status == 2">禁用</span>  </div>
                        <div class="table-item" style="width:20%">
                            <el-button title="编辑账号" type="primary" @click="editAccount(item.account,item.name,item.groupName,item.roleName,item.privileges,item.userId)" icon="el-icon-edit" circle></el-button>
                            <el-button title="禁用账号" type="warning" :style="item.status=='2'? 'background-color:#dddddd;border:1px solid #dddddd':''" :icon="item.status=='2'? 'el-icon-success':'el-icon-remove'"  :disabled="item.roleId== '1'" @click="changeStatusAccount(item.userId,item.status)" circle></el-button>
                            <el-button title="删除账号" type="danger" icon="el-icon-delete" @click="deleteAccount(item.userId)" :disabled="item.roleId== '1'" circle></el-button>
                        </div>
                    </div>
                 </div>
                <el-dialog
                    :title="diaglogTitle"
                    :visible.sync="dialogVisible"
                    :close-on-click-modal="false"
                    width="40%">
                    <div class="dialog-content">
                    <el-form ref="form" :model="changeAcountForm" label-width="90px">
                        <el-form-item label="邮箱地址">
                            <el-input placeholder="请输入邮箱账号（如果是企业用户，请使用企业邮箱）" v-model="changeAcountForm.emailArresss" :disabled="isEditAccount"></el-input>
                        </el-form-item>
                        <el-form-item label="姓名">
                            <el-input placeholder="请输入账号使用者姓名" v-model="changeAcountForm.fullName"></el-input>
                        </el-form-item>
                        <el-form-item label="账号类型">
                            <el-select v-model="changeAcountForm.accountType" placeholder="请选择账号类型" :disabled="isSuperAcconut" @change="changeAccountType">
                            <el-option v-for="type in accountTypes" :key="type" :label="type"
                                       :value="type"></el-option>
                            <!-- <el-option label="人工客服" value="人工客服"></el-option>
                            <el-option label="工单成员" value="工单成员"></el-option> -->
                            </el-select>
                        </el-form-item>
                        <el-form-item label="账号分组">
                            <el-select v-model="changeAcountForm.groupName" placeholder="请选择账号所属分组">
                                <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="机器人权限" v-if="isShowBotAuthority">
                            <div class="auth-content">
                                <!-- <el-button style="display:none;" @click="setCheckedKeys" v-clickDown></el-button>
                                <el-button @click="getCurrentCheckedTree"></el-button>
                                <el-tree
                                :data="botSkillList"
                                v-model="selectTree"
                                default-expand-all
                                show-checkbox
                                node-key="id"
                                ref="tree"
                                highlight-current
                                :props="botProps">
                                </el-tree> -->
                                    <div class="content">
                                        <div class="bot">
                                        <el-checkbox :disabled="isSuperAcconut" style="text-align:left" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                                            <el-checkbox-group :disabled="isSuperAcconut" v-model="botCheckList" @change="handleCheckedBotChange" style="display:flex;flex-direction: column;justify-content: flex-start;text-align:left">
                                                <el-checkbox v-for="item in botList"  :key="item.id" :label="item"
                                                             name="type">{{item}}</el-checkbox>
                                            </el-checkbox-group>
                                        </div>
                                    </div>
                            </div>
                        </el-form-item>
                    </el-form>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="saveAccount"><span v-if="diaglogTitle == '新增账号'">立即邀请</span><span v-if="diaglogTitle == '编辑账号'">保 存</span></el-button>
                    </span>
                </el-dialog>
                <el-dialog
                    title="分组管理"
                    :close-on-click-modal="false"
                    :visible.sync="dialogGroupVisible"
                    width="40%">
                    <div class="dialog-content">
                    <el-form ref="form" >
                        <el-form-item>
                            <div class="group-content">
                                <div class="title">
                                    <div class="bot">序号</div>
                                    <div class="skill">名称</div>
                                </div>
                                <div class="content" @mouseover="isGroupEdit = true" @mouseout="isGroupEdit = false">
                                    <div class="bot">1</div>
                                    <div class="skill">分组名称</div>
                                    <div class="text-right" v-show="isGroupEdit"><el-button type="text"><i class="arsenal_icon arsenaledit-solid"></i></el-button><el-button type="text"><i class="arsenal_icon arsenalwindow-close-solid"></i></el-button></div>
                                </div>
                            </div>
                        </el-form-item>
                    </el-form>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogGroupVisible = false">取 消</el-button>
                        <el-button type="primary" @click="dialogGroupVisible = false">确 定</el-button>
                    </span>
                </el-dialog>
                <!-- 分组管理 -->
                <el-dialog id="diaHandleClass" title="用户组管理" :close-on-click-modal="false" :visible.sync="classHandleVisible" width="500px">
                    <div>
                        <div class="tar">
                        <el-button @click="addClass" type="primary" size="small">新建分组</el-button>
                        </div>
                        <div class="classListBox">
                        <ul id="classList" class="classList">
                            <li v-for="(item, index) in groupList" :key="index">
                            <span class="num">{{index + 1}}</span>
                            <div class="content">
                                <span v-show="!item.isEdit">{{item.name}}</span>
                                <span v-show="item.isEdit">
                                <el-input size="small" v-model="item.name" maxlength="15"></el-input>
                                </span>
                            </div>
                            <div class="classHandle">
                                <em @click="editClass(index)" v-show="!item.isEdit" class="el-icon-edit"></em>
                                <em @click="deleteClass(index)" class="el-icon-delete"></em>
                            </div>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button size="small" @click="classHandleVisible = false">取 消</el-button>
                        <el-button size="small" type="primary" @click="saveClassHandle">保 存</el-button>
                    </span>
                </el-dialog>
             </div>
             <div class="pagination">
                    <el-pagination
                    v-if="pageshow"
                        :background="true"
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                        :page-size ="15"
                        :current-page.sync="currentPage"
                        :total="accountData.total">
                    </el-pagination>
                 </div>
         </div>
     </div>
</template>
<script>
import BotIndexVue from '../botIndex/BotIndex.vue'

export default {
    name: "accountManage",
    directives: {
        clickDown: {
            inserted(el) {
                el.click()
            }
        }
    },
    data(){
        return{
            activePageInfo: {
                headerName: "成员管理",
            },
            dialogVisible: false,
            dialogGroupVisible: false,
            diaglogTitle: "",
            changeAcountForm: {
                emailArresss: '',
                fullName: '',
                accountType: '',
                groupName: '',
                groupId: '',
                roleId: '',
                accountAuthority: [],
                userId: ''
            },
            isGroupEdit: false,
            accountList: [],
            accountData: {pages:'',},
            currentPage: 1,
            botList: [],
            botNum: '',
            currentAccountBotNum: '',
            botInfoList:[],
            roleList: [],
            botSkillList: [],
            botProps: {
                label: 'name',
            },
            botKeys: '',
            botCheckList: [],
            privileges: [],
            skillCheckList: [],
            checked: true,
            selectTree: [],
            selectCurrentTree: [],
            skills:'',
            checkAll: true,
            isIndeterminate: false,
            //用户组管理
            classHandleVisible: false,
            groupList: [],
            pageshow: true,
            currentStatus: '',
            loading: true,
            isSuperAcconut: false,
            isEditAccount: false,
            isShowBotAuthority: true,
            accountTypes:[
                "人工客服",
                "工单成员"
            ]
        }
    },
    methods: {
        // 获取机器人列表
        getBotList() {
            this.FetchGet(this.requestUrl.bot.getBotList, {
                page: 1,
                size: 100
            }).then(res => {
                if (res.code === "0") {
                    //this.botList = res.data.list;
                    for(let i=0; i<res.data.list.length ;i++){
                        /* let botId = this.botList[i].id;
                        let botName = this.botList[i].name;
                        this.getSkillList(botId,botName); */
                        this.botInfoList.push({
                            botId: res.data.list[i].id,
                            botName: res.data.list[i].name,
                        })
                        this.botList.push(
                            res.data.list[i].name,
                        )
                    }
                    this.botCheckList = this.botList;
                    this.botNum = this.botList.length;
                    console.log('botCheckList:'+JSON.stringify(this.botCheckList))
                        
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            });
        },
        // 获取角色列表
        getRoleList() {
            this.FetchGet(this.requestUrl.role.getRoleList).then(res => {
                if (res.code === "0") {
                    this.roleList = res.data;
                    console.log('this.roleList'+JSON.stringify(this.roleList))
                        
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            });
        },
        getAccountList(pageNo,pageSize){
            console.log(pageNo,pageSize)
            this.accountList = []
            this.accountData = {}
            console.log(this.requestUrl.accountManage.getAccountList)
            this.FetchGet(this.requestUrl.accountManage.getAccountList,{
                pageNo: pageNo,
                pageSize: pageSize
            }).then(res => {
                    console.log(res.data)
                if (res.code === "0") {
                    this.accountList = res.data.list;
                    this.accountData = res.data;
                    this.getJsonLength(res.data.list.privileges)
                    console.log('this.accountList'+JSON.stringify(this.accountList))
                    console.log('this.accountData'+this.accountData.total)
                    console.log(this.accountData)
                    this.loading = false;
                } else {
                    this.$message.error(res.message);
                }
                }
            );

        },
        handleCurrentChange(val){
            this.currentPage = val
            console.log('currentPage:'+this.currentPage)
            this.pageshow = false;//让分页隐藏
                this.$nextTick(() => {//重新渲染分页
                    this.pageshow = true;
            });
            this.getAccountList(val,15)
        },
        // 全选反选
        handleCheckAllChange(val) {
            this.botCheckList = val ? this.botList :  [];
            console.log('all:'+JSON.stringify(this.botCheckList))
        },
        // 选择机器人权限
        handleCheckedBotChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.botList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.botList.length;
            console.log('geti:'+JSON.stringify(this.botCheckList))

        },
        // 获取某机器人知识库列表
        getSkillList(botId,botName) {
            this.FetchGet(this.requestUrl.skill.getSkillList, {
                bid: botId,
            }).then(res => {
                if (res.code === "0") {
                    this.botSkillList.push({
                        id: botId,
                        name: botName,
                        children: res.data
                    })
                    console.log('this.botSkillList:'+JSON.stringify(this.botSkillList));
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            });
        },
        setCheckedKeys(val) {
            this.$refs.tree.setCheckedNodes(this.botSkillList);
            console.log('selectTree:'+this.selectTree)
        },
        //获取el-tree当前选中，无知识库暂不用
        /* getCurrentCheckedTree () {
            let res = this.$refs.tree.getCheckedNodes()
            console.log('res:'+JSON.stringify(res))
            if(res){
                res.forEach(item => {
                    if(item.children){
                        item.children.forEach(item2 => {
                        console.debug(item2);
                        console.debug(item)
                        this.selectTree.push({
                        botId: item.id,
                        skillIds: item2.id
                    })

                    });
                    }]
                });
            }
            let mergeJson = this.mergeJson(this.selectTree);
            console.log('merge json :',mergeJson);
            console.log('this.selectTree:'+JSON.stringify(this.selectTree))
        }, */
        //合并数组，无知识库暂不用
        mergeJson(arr){
            let botIds = [];
            let res = [];
            if(arr!=null && arr.length>0){
                for (let  i=0,len=arr.length;i<len;i++){
                    let node = arr[i];
                    if (botIds.indexOf(node.botId)==-1){
                        let item = {botId:node.botId,skillIds:[node.skillIds]};
                        res.push(item);
                        botIds.push(node.botId);
                    }else {
                        for (let j=0,l = res.length;j<l;j++){
                            if (res[j].botId == node.botId){
                                res[j].skillIds.push(node.skillIds);
                                break;
                            }
                        }
                    }
                }
            }
            return res;
        },
        getJsonLength(jsonData){
            var jsonLength = 0;
            for(var item in jsonData){
            jsonLength++;
            }
            //return jsonLength;
            this.currentAccountBotNum = jsonLength
        },
        changeAccountType(value){
            console.log("current value:",value);
            if(value=="人工客服"){
                this.isShowBotAuthority=true;
            }else if(value=="工单成员"){
                this.isShowBotAuthority=false;
            }
        },
        addAccount(){
            this.dialogVisible = true;
            this.isShowBotAuthority = true;
            this.isSuperAcconut = false;
            this.isEditAccount = false;
            this.diaglogTitle = '新增账号';
            this.changeAcountForm.emailArresss = '';
            this.changeAcountForm.fullName = '';
            this.changeAcountForm.roleName = '';
            this.changeAcountForm.groupName = '';
            this.changeAcountForm.accountType = '';
            this.accountGroupList();
            
        },
        editAccount(account,name,groupName,roleName,privileges,userId){
            //console.log('editaccount:'+account+name+roleName)
            //console.log('editaccount，privileges:'+privileges)
            if(roleName == '超级管理员'){
                this.isSuperAcconut = true;
                this.isShowBotAuthority=true;
            }else if(roleName == '工单成员'){
                this.isShowBotAuthority=false;
            }else{
                this.isSuperAcconut = false;
                this.isShowBotAuthority=true;
            }
            this.isEditAccount = true;
            this.dialogVisible = true;
            this.diaglogTitle = '编辑账号';
            this.accountGroupList();
            this.changeAcountForm.emailArresss = account;
            this.changeAcountForm.fullName = name;
            this.changeAcountForm.userId = userId;
            this.changeAcountForm.accountType = roleName;
            this.changeAcountForm.groupName = groupName;
            this.botCheckList = [];
            this.isIndeterminate = true;
            let privilegesNew = JSON.parse(privileges)
            //console.log('privilegesNew'+privilegesNew);
            for (let i = 0; i < privilegesNew.length; i++) {
               this.botCheckList.push(privilegesNew[i].botName)
            }
            //console.log('botchecklist:'+this.botCheckList   )
        },
        // 保存账号
        saveAccount(){
            if(this.isShowBotAuthority){
                if (this.botCheckList.length > 0) {
                    this.privileges = []
                    for(let i=0; i<this.botCheckList.length; i++){
                        for (let s = 0; s < this.botInfoList.length; s++) {
                            if (this.botCheckList[i] ==this.botInfoList[s].botName) {
                                this.privileges.push({
                                    botId: this.botInfoList[s].botId,
                                    botName: this.botInfoList[s].botName,
                                    skillIds: ''
                                })
                            }
                        }
                    }
                } else {
                    this.$message.error('您还没选择机器人，请选择后再添加。');
                    return
                }
            }else{
                this.privileges = [];
            }
            //console.log('this.privileges'+JSON.stringify(this.privileges))
            //console.log('this.changeAcountForm.accountType'+JSON.stringify(this.changeAcountForm.accountType))
            if (this.changeAcountForm.accountType.length>0) {
                for (let index = 0; index < this.roleList.length; index++) {
                    if (this.roleList[index].name == this.changeAcountForm.accountType) {
                        this.changeAcountForm.roleId = this.roleList[index].id
                    }
                    
                }
            }
            else {
                this.$message.error('请您选择账户类型');
                return
            }
            // 生成GroupID
            if (this.changeAcountForm.groupName.length>0) {
                for (let index = 0; index < this.groupList.length; index++) {
                    if (this.groupList[index].name == this.changeAcountForm.groupName) {
                        this.changeAcountForm.groupId = this.groupList[index].id
                    }
                    
                }
            }
            else {
                this.$message.error('请您选择用户分组，如果分组为空，请先添加分组！');
                return
            }
            if(this.changeAcountForm.emailArresss == ''){
                this.$message.error('邮箱不能为空，请填写后再进行保存！');
                return
            }
            //console.log('this.changeAcountForm.roleId'+JSON.stringify(this.changeAcountForm.roleId))
            let email = this.changeAcountForm.emailArresss
            let name = this.changeAcountForm.fullName
            let roleId = this.changeAcountForm.roleId
            let groupId = this.changeAcountForm.groupId
            let userId = this.changeAcountForm.userId
            if(this.diaglogTitle == '新增账号'){
                this.FetchPost(
                    this.requestUrl.accountManage.account,{
                        account: email,
                        name: name,
                        email: email,
                        privileges: JSON.stringify(this.privileges),
                        roleId: roleId,
                        groupId: groupId
                    }
                ).then(res => {
                    if (res.code === "0") {
                        this.$message({
                            message: '账号添加成功！',
                            type: 'success'
                        });
                        this.changeAcountForm = {
                            emailArresss: '',
                            fullName: '',
                            accountType: '',
                            groupName: '',
                            groupId: '',
                            roleId: '',
                            accountAuthority: [],
                            userId: ''
                        };
                        this.dialogVisible = false;
                        // 跳转到最后一页
                        this.FetchGet(this.requestUrl.accountManage.getAccountList,{
                            pageNo: 1,
                            pageSize: 15
                        }).then(
                            res => {
                            if (res.code === "0") {
                                let pages = res.data.pages;
                                this.getAccountList(pages,15);
                            } else {
                                this.$message.error(res.message);
                            }
                            }
                        );

                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
            if(this.diaglogTitle == '编辑账号'){
                this.FetchPut(
                    this.requestUrl.accountManage.updateAccount, userId,    {
                        account: email,
                        name: name,
                        email: email,
                        privileges: JSON.stringify(this.privileges),
                        roleId: roleId,
                        groupId: groupId,
                    }
                ).then(res => {
                    if (res.code === "0") {
                        this.$message({
                            message: '账号修改成功！',
                            type: 'success'
                        });
                        this.changeAcountForm = {
                            emailArresss: '',
                            fullName: '',
                            accountType: '',
                            groupName: '',
                            groupId: '',
                            roleId: '',
                            accountAuthority: [],
                            userId: ''
                        };
                        this.dialogVisible = false;
                        // 跳转到最后一页
                        this.FetchGet(this.requestUrl.accountManage.getAccountList,{
                            pageNo: 1,
                            pageSize: 15
                        }).then(
                            res => {
                            if (res.code === "0") {
                                let pages = res.data.pages;
                                this.getAccountList(this.currentPage,15);
                            } else {
                                this.$message.error(res.message);
                            }
                            }
                        );

                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
            
        },
        changeStatusAccount(userId,status){
            if(status == '2'){
                this.$confirm('账号恢复后可正常使用, 是否继续?', '账号恢复', {
                    confirmButtonText: '恢复',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.currentStatus = 1;
                        this.FetchPut(
                            this.requestUrl.accountManage.updateAccount, userId,    {
                                status:this.currentStatus
                            }
                        ).then(res => {
                            if (res.code === "0") {
                                this.$message({
                                    message: '账号修改成功！',
                                    type: 'success'
                                });
                                this.getAccountList(this.currentPage,15);
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                    }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消恢复'
                    });          
                });
            }
            if(status == '1'){
                this.$confirm('账号被禁用后将无法登陆系统, 是否继续?', '账号禁用', {
                    confirmButtonText: '禁用',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.currentStatus = 2;
                        this.FetchPut(
                            this.requestUrl.accountManage.updateAccount, userId,    {
                                status:this.currentStatus
                            }
                        ).then(res => {
                            if (res.code === "0") {
                                this.$message({
                                    message: '账号修改成功！',
                                    type: 'success'
                                });
                                this.getAccountList(this.currentPage,15);
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                    }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消禁用'
                    });          
                });
            }
            if(status == '0'){
                this.$message.error('请先激活账号后再进行操作！');
                return
            }
        },
        editGroup(){
            this.dialogGroupVisible = true;
        },
        showAllBot(){
            let div = document.getElementById('allbot')
            div.style.display = "flex"
            console.log('div:'+div)
        },
        // 删除账号
        deleteAccount(userId,index){
            this.$confirm('删除账号之后，该账号将不可恢复，是否删除？', '删除账号', {
                distinguishCancelAndClose: true,
                confirmButtonText: '删除',
                cancelButtonText: '取消'
            })
            .then(() => {
                this.FetchDelete(this.requestUrl.accountManage.deleteAccount,'member?userId='+userId).then(
                res => {
                    if (res.code === "0") {
                        this.$message({
                        type: 'success',
                        message: '删除成功'
                        });
                        this.getAccountList(this.currentPage,15)
                    } else {
                        this.$message.error(res.message);
                    }
                    }
                );
            })
            .catch(action => {
                this.$message({
                type: 'info',
                message: action === 'cancel'
                    ? '取消删除'
                    : '停留在当前页面'
                })
            });
        },
        // 获取用户分组list
        accountGroupList() {
            this.FetchGet(this.requestUrl.accountGroup.getAccountGroupList).then(res => {
                if (res.code === "0") {
                this.groupList = res.data;
                console.log('this.groupList'+this.groupList)
                } else {
                this.$message.error(res.message);
                }
            });
        },
        // 点击用户组管理
        clickClassHandle() {
            this.classHandleVisible = true;
            this.accountGroupList();
        },
        // 新建用户分组
        addClass() {
            this.groupList.push({
                isEdit: true,
                name: ""
            });
            this.$nextTick(() => {
                let ele = document.getElementById("classList");
                ele.scrollTop = ele.scrollHeight + 100;
            });
        },
        // 编辑用户分组
        editClass(index) {
            this.groupList[index].isEdit = true;
            this.groupList = [...this.groupList];
        },
        // 删除用户分组
        deleteClass(index) {
            //判断用户分组下是否存在用户关联
            let params = {
                groupId:  this.groupList[index].id
            };
            for (let i = 0; i < this.accountList.length; i++) {
                if (this.accountList[i].groupId == this.groupList[index].id && this.groupList[index].name !='' && index>= this.groupList.length) {
                    this.$message.error('当前分类已有用户关联，请先取消关联后再进行删除！');
                    return;
                }
                
            }
            this.groupList.splice(index, 1);
        },
        // 保存用户分组
        saveClassHandle() {
            //判断新建的分类名称是否为空
            let lastName = this.groupList.length -1;
            let newClassName = this.groupList[lastName];
            console.log('newClassName:'+JSON.stringify(newClassName))
            if (newClassName.name !=0) {
                this.FetchPut(this.requestUrl.accountGroup.updateAccountGroup, '',this.groupList).then(res => {
                if (res.code === "0") {
                    this.$message({
                            message: '用户分组更新成功',
                            type: 'success'
                    });
                    this.accountGroupList();
                    this.getAccountList(this.currentPage,15);
                    this.classHandleVisible = false;
                } else {
                    this.$message.error(res.message);
                }
                });
            }else{
                this.$message.error('分组名称不能为空哦，请填写后保存！');
            }
        },
    },
    mounted() {
        this.getAccountList(1,15)
        this.getBotList()
        this.getRoleList()
    }
    
}
</script>
<style lang="less" scoped>
@import "./../../assets/less/accountManage/accountManage.less";
.leftInfo{
    display: flex !important;
    justify-content: space-between !important;

}
.module-result-content-field {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    .result-content-field {
        flex: 1;
        position: relative;
        .el-table {

            position: absolute;
            overflow: hidden;

        }

    }
}
</style>