//guide.js
const steps = [
    {
        element: ".guoran-tongyichicun-jiqirentouxiang",
        popover: {
            title: "机器人管理",
            description: "机器人的知识库，对话流程配置都从这个入口进去。",
            position: "right",
        },
    },
    {
        element: ".guoran-tongyichicun-jiqirentouxiang",
        popover: {
            title: "机器人管理",
            description: "机器人的知识库，对话流程配置都从这个入口进去。",
            position: "right",
        },
    },
    {
        element: ".guoran-zhinengwendangchouqu",
        popover: {
            title: "智能文档抽取",
            description: "智能文档抽取可将已有文档知识库抽取出机器人可用的知识库，有效减少人员维护知识库的成本。",
            position: "right",
        },
    },
    {
        element: ".guoran-tongyichicun-shitiguanli",
        popover: {
            title: "实体管理",
            description:
                "实体全程为命名实体，可以是人名、机构名、地名、时间、品牌名、型号名等等，意图识别后，通过对实体进行识别来判断用户更精细的需求，以便提供更精细的答案。",
            position: "right",
        },
    },
    {
        element: ".guoran-tongyichicun-jiekouduijie",
        popover: {
            title: "系统对接",
            description:
                "该功能是对话系统和您的业务系统进行联通的桥梁，可在对话中查询展示业务系统的信息，同时可也以上传业务系统所需的数据。",
            position: "right",
        },
    },
    {
        element: ".guoran-tongyichicun-jiqixuexi",
        popover: {
            title: "机器学习",
            description:
                "对话机器人随着用户不断使用，是可以不断的进行自我学习的，同时也需要人工辅助进行校正，以便更好的学习，在此进行。",
            position: "right",
        },
    },
    {
        element: ".guoran-tongyichicun-duihuajilu",
        popover: {
            title: "对话记录",
            description: "机器人上线后，您可以在这里查看机器人和用户的对话。",
            position: "right",
        },
    },
    {
        element: ".guoran-tongyichicun-tongjifenxi",
        popover: {
            title: "统计分析",
            description: "在这里可以查看机器人工作的一些基础信息。",
            position: "right",
        },
    },
    {
        element: ".new-help-tips",
        popover: {
            title: "帮助文档",
            description:
                "同时我们为您准备了帮助文档，以图文和视频的形式向您展示平台的一些功能和使用说明",
            position: "left",
        },
        padding: 5,
    },
    {
        element: "#addBot",
        popover: {
            title: "添加机器人",
            description:
                "可以点这里，立即创建一个机器人，开始配置您的对话机器人吧",
            position: "bottom",
        },
        padding: 5,
    },
    {
        element: ".templateBots",
        popover: {
            title: "通过模板创建",
            description:
                "我们也提供一些常用的模板，可以修改一下答案，立即开始试用，会节省不少时间，同时我们也提供了示例机器人，可以看看标准的配置。",
            position: "top",
        },
        padding: 5,
    },
    {
        element: ".exmple",
        popover: {
            title: "示例机器人",
            description:
                "您看完帮助文档后，通过使用示例机器人，可以进行实操，进一步理解对话机器人的设计原理，快速设计自己的机器人，请点击《使用该机器人》按钮进行体验",
            position: "right",
        },
    },
];

export default steps;
